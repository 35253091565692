/*
 * @Author: 段海兵
 * @Date: 2022-10-25 11:17:35
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
 */
// 公用配置文件
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
// 引入用于请求入参加密
const base64 = require('./base64').Base64
const md5 = require('./md5')
const browser = require('./browser')

// 请求地址
const requestAddress = setting.httpAddress

// 加密方法
export const get16 = (a, v, p) => {
  const pp = {}
  const _t = new Date().getTime() + ''
  const _p = JSON.stringify(p)
  pp._0x0111 = base64.encode(_t)
  pp._0x1011 = base64.encode(a)
  pp._0x1100 = base64.encode(v)
  pp._0x1110 = base64.encode(encodeURIComponent(_p))
  pp._0x1001 = md5(pp._0x0111 + pp._0x1011 + pp._0x1100 + pp._0x1110).toUpperCase()
  const hostname = (setting.httpAddress || '').replace(/(http[s]?:\/\/)?([^:]+)([:])?([\d]+)?/, '$2')
  pp._0x1101 = base64.encode(hostname)
  return pp
}
export const getK = (a, v, p) => {
  // _params.._version .. _timestamp .. _api_name
  const pp = {}
  const _t = new Date().getTime() + ''
  const _p = JSON.stringify(p)
  pp.KInGDOM = base64.encode(_t)
  pp.KINGdOM = base64.encode(a)
  pp.KINGDoM = base64.encode(v)
  pp.KiNGDOM = base64.encode(encodeURIComponent(_p))
  pp.kINGDOM = md5(
    pp.KiNGDOM + pp.KINGDoM + pp.KInGDOM + pp.KINGdOM
  ).toUpperCase()
  const hostname = (setting.httpAddress || '').replace(/(http[s]?:\/\/)?([^:]+)([:])?([\d]+)?/, '$2')
  const protocol = (setting.httpAddress || '').replace(/(http[s]?:\/\/)?([^:]+)([:])?([\d]+)?/, '$4')
  pp.KINgDOM = base64.encode(hostname)
  pp.KINGDOm = base64.encode(protocol)
  return pp
}
export const getL = (a, v, p) => {
  const pp = {}
  const _t = new Date().getTime() + ''
  const _p = JSON.stringify(p)
  const hostname = (requestAddress || '').replace(/(http[s]?:\/\/)?([^:]+)([:])?([\d]+)?/, '$2')
  pp.css = base64.encode(_t)
  pp.android = base64.encode(a)
  pp.html = base64.encode(v)
  pp.ios = base64.encode(encodeURIComponent(_p))
  pp.js = md5(pp.ios + pp.android + pp.css + pp.html).toUpperCase()
  pp.wp = base64.encode(hostname)
  return pp
}
export const getAjaxParams = (a, v, p) => {
  p.stationtype = setting.stationtype
  p.osversion = browser.OS
  p.browser = browser.browser
  p.browserversion = browser.version
  if (setting.encodeParam) {
    // 如果加密
    const random = Math.floor(Math.random() * 10) % 3
    if (random === 0) {
      return get16(a, v, p)
    } else if (random === 1) {
      return getK(a, v, p)
    } else {
      return getL(a, v, p)
    }
  }
  const test_param = {}
  test_param.a = a
  test_param.v = v
  test_param.p = JSON.stringify(p)
  return test_param
}
