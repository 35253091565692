/*
 * @Author: 段海兵
 * @Date: 2022-10-25 18:36:26
 * @LastEditors: 段海兵
 * @Description: filter
 * @email: duanhb@bayconnect.com.cn
 */
import Vue from 'vue'
import { formatDate, formatTime, formatDateTime, fixNum, formatPercent, formatMicrometer } from '@/utils'

// 格式化日期 yyyy-MM-dd 入参：date
Vue.filter('formatDate', formatDate)
// 格式化时间 hh:mm:ss 入参：time
Vue.filter('formatTime', formatTime)
// 格式化日期 yyyy-MM-dd hh:mm:ss 入参：time
Vue.filter('formatDateTime', formatDateTime)
// 格式化数字 num(需要格式化的数字) fix(需要保留的小数位数，默认为0)
Vue.filter('fixNum', fixNum)
// 格式化百分比 num值 fix保留小数位数 isMult是否已经乘以100
Vue.filter('formatPercent', formatPercent)
// 格式化千分比 num值 fix保留小数位数 isMult是否已经乘以1000
Vue.filter('formatMicrometer', formatMicrometer)
