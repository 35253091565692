import { getApi, loginName } from '@/utils/request'
export default {
  state: {
    // 是否登录超时
    loginTimeOut: false,
    // 登录名
    loginName: '',
    // 用户信息(注册时填写，确认不是游客时即品种权限已开通可以使用state.fund.custInfo)
    accountInfo: {}
  },
  mutations: {
    // 登出操作
    setLoginTimeOut(state, status) {
      state.loginTimeOut = status
    },
    // 保存用户信息
    setAccountInfo(state, info) {
      state.accountInfo = info
    },
    // 保存登录名
    setLoginName(state, name) {
      state.loginName = name
    }
  },
  actions: {
    /**
     * 获取用户信息
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    getAccountInfo({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        getApi('kingdom.kfts.get_current_cust_info', 'V1.0', { ...params })
          .then(kdjson => {
            const flag = kdjson.flag
            const items = kdjson.items
            if (flag === '1' && items) {
              commit('setAccountInfo', items[0])
              resolve(kdjson)
            } else {
              commit('setAccountInfo', {})
              reject(kdjson)
            }
          }).catch(kdjson => {
            commit('setAccountInfo', {})
            reject(kdjson)
          })
      })
    },
    /**
     * 获取用户信息
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    getLoginName({
      commit
    }) {
      return new Promise((resolve, reject) => {
        loginName().then(kdjson => {
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            commit('setLoginName', items[0].login_name)
            commit('setLoginTimeOut', false)
            resolve(kdjson)
          } else {
            commit('setLoginName', '')
            commit('setLoginTimeOut', true)
            reject(kdjson)
          }
        }).catch(kdjson => {
          commit('setLoginName', '')
          commit('setLoginTimeOut', true)
          reject(kdjson)
        })
      })
    },
    /**
     * 设置登录超时
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    setLoginTimeOutStatus({ commit }, status) {
      commit('setLoginTimeOut', status)
    },
    /**
     * 清空用户信息
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    clearAccountInfo({
      commit
    }) {
      commit('setLoginName', '')
      commit('setAccountInfo', {})
      commit('setLoginTimeOut', true)
    }
  }
}
