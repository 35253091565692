<template>
  <el-dialog
    title="修改密码"
    width="400px"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <el-form ref="passwordForm" :model="form" status-icon :rules="rules" label-width="0" class="demo-ruleForm">
      <el-form-item :label="''" prop="oldpassword">
        <el-input v-model="form.oldpassword" type="password" placeholder="旧密码" autocomplete="off" />
      </el-form-item>
      <el-form-item :label="''" prop="password">
        <el-input v-model="form.password" type="password" placeholder="新密码" autocomplete="off" />
      </el-form-item>
      <el-form-item :label="''" prop="repassword">
        <el-input v-model="form.repassword" type="password" placeholder="确认密码" autocomplete="off" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="success" class="w-100" :loading="loading" @click="handleUpdateSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getApi, logout } from '@/utils/request'
import kdes from '@/utils/kjax.des'
import { requiredRules, passwordRules } from '@/utils/validate'
export default {
  name: 'Password',
  data() {
    return {
      dialogVisible: false,
      form: {
        oldpassword: '',
        password: '',
        repassword: ''
      },
      rules: {
        oldpassword: [
          requiredRules('旧密码')
        ],
        password: [
          requiredRules('新密码'),
          passwordRules('新密码')
        ],
        repassword: [
          requiredRules('确认密码'),
          {
            validator: (rule, value, callback) => {
              // 上面有说，返回true表示校验通过，返回false表示不通过
              if (this.form.password === value) {
                callback()
              } else {
                callback(new Error('两次密码输入不一致'))
              }
            },
            trigger: ['change']
          }
        ]
      },
      imgSrc: '',
      loading: false
    }
  },
  computed: {},
  created() {},
  methods: {
    handleShow() {
      if (this.$refs.passwordForm && this.$refs.passwordForm.resetFields) {
        this.$refs.passwordForm.resetFields()
      }
      this.dialogVisible = true
    },
    handleUpdateSubmit() {
      console.log(this.$refs)
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          getApi('kingdom.kfts.set_out_exc_modifypwd', 'V1.0', {
            ...this.form,
            oldpassword: kdes.getDes(this.form.oldpassword),
            password: kdes.getDes(this.form.password),
            msgcode: '00000000',
            business_code: '8003'
          }).then((kdjson) => {
            const flag = kdjson.flag
            if (flag === '1') {
              this.dialogVisible = false
              logout().then(kdjson2 => {
                this.loading = false
                const flag2 = kdjson2.flag
                if (flag2 === '1') {
                  this.$alert(kdjson.msg || '密码修改成功', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                      this.$store.dispatch('clearAccountInfo')
                    }
                  })
                } else {
                  this.$message({
                    message: kdjson2.msg || '修改失败！',
                    type: 'error'
                  })
                }
              }).catch((kdjson2) => {
                this.loading = false
                this.$message({
                  message: kdjson2.msg,
                  type: 'error'
                })
              })
            } else {
              this.loading = false
              this.$message({
                message: kdjson.msg || '修改失败！',
                type: 'error'
              })
            }
          }).catch((kdjson) => {
            this.loading = false
            this.$message({
              message: kdjson.msg,
              type: 'error'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
