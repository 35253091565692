/*
 * @Author: 段海兵
 * @Date: 2022-10-24 09:54:28
 * @LastEditors: 段海兵
 * @Description: element按需加载
 * @email: duanhb@bayconnect.com.cn
 */
import Vue from 'vue'
import './theme/index.css'
import { Button, ButtonGroup, Divider, Card, Icon, Empty, Dropdown, DropdownMenu, DropdownItem, Dialog, Form, FormItem, Input, Select, Option, Loading, Descriptions, DescriptionsItem, Tag, Link, Tooltip, Alert, RadioGroup, RadioButton, Table, TableColumn, Row, Col, Pagination, Result, Message, MessageBox } from 'element-ui'

Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Divider)
Vue.use(Card)
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Loading.directive)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Tag)
Vue.use(Link)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Result)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$loading = Loading.service
