<!--
 * @Author: 段海兵
 * @Date: 2022-10-25 17:41:58
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <el-dialog
    title="账户信息"
    width="60%"
    :visible.sync="dialogVisible"
    append-to-body
    destroy-on-close
  >
    <div class="user-info-container scrollbar">
      <el-descriptions
        title=""
        border
        :column="2"
      >
        <el-descriptions-item label="交易账号" :span="1">{{ accountInfo.custno }}</el-descriptions-item>
        <el-descriptions-item label="单位全称" :span="1">{{ accountInfo.name }}</el-descriptions-item>
        <el-descriptions-item label="证件类别" :span="1">{{ accountInfo.certificatetypename }}</el-descriptions-item>
        <el-descriptions-item label="证件号码" :span="1">{{ accountInfo.certificateno }}</el-descriptions-item>
        <el-descriptions-item label="单位类别" :span="1">{{ accountInfo.investortypechname }}</el-descriptions-item>
        <el-descriptions-item label="Email" :span="1">{{ accountInfo.emailaddress }}</el-descriptions-item>
        <el-descriptions-item label="移动电话" :span="1">{{ accountInfo.mobileno }}</el-descriptions-item>
        <el-descriptions-item label="联系人" :span="1">{{ accountInfo.contactname }}</el-descriptions-item>
        <el-descriptions-item label="法人" :span="2">{{ accountInfo.instreprname }}</el-descriptions-item>
        <el-descriptions-item label="联系地址" :span="2">{{ accountInfo.address }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'UserInfo',
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: mapState({
    accountInfo: state => state.account.accountInfo
  }),
  created() {},
  methods: {
    handleShow() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.user-info-container {
  // height: 37.5rem;
  overflow-y: auto;
  ::v-deep {
    .el-descriptions {
      // margin-bottom: 2rem;
      .el-descriptions-item__label {
        width: 7.5rem;
        padding: 0.75rem 0.625rem;
        font-size: 1rem;
      }
      .el-descriptions-item__content{
        padding: 0.75rem 0.625rem;
        font-size: 1rem;
      }
    }
  }
}
</style>
