<template>
  <div class="login">
    <div class="login-btn cl-white" @click="handleLogin">
      <slot /><i class="el-icon-right" />
    </div>

    <el-dialog
      class="login-dialog"
      title="登录"
      width="400px"
      :visible.sync="dialogVisible"
      append-to-body
      @opened="handleOpened"
    >
      <div class="login-alert">
        <el-alert
          title="温馨提示：竞价参与人您好，您可以使用北京市碳排放权电子交易平台的账户名密码进行登录"
          type="info"
          :closable="false"
        />
      </div>
      <el-form ref="loginForm" :model="form" status-icon :rules="rules" label-width="0" class="demo-ruleForm">
        <el-form-item :label="''" prop="loginName">
          <el-input v-model="form.loginName" placeholder="用户名" prefix-icon="el-icon-user" autocomplete="off" />
        </el-form-item>
        <el-form-item :label="''" prop="loginPwd">
          <el-input v-model="form.loginPwd" type="password" placeholder="密码" prefix-icon="el-icon-lock" autocomplete="off" />
        </el-form-item>
        <el-form-item :label="''" prop="validcode">
          <div class="volidcode flex">
            <el-input v-model="form.validcode" class="flex-1" placeholder="验证码" prefix-icon="el-icon-mobile-phone" autocomplete="off" @keyup.enter.native="handleLoginSubmit" />
            <img :src="imgSrc" alt="点击刷新" @click="handleRefreshCode">
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" class="w-100" :loading="loading" @click="handleLoginSubmit">登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApi } from '@/utils/request'
import kdes from '@/utils/kjax.des'
import { loginUserRequest } from '@/utils/userInfo'
import { requiredRules } from '@/utils/validate'
export default {
  name: 'Login',
  data() {
    return {
      dialogVisible: false,
      form: {
        loginName: '',
        loginPwd: '',
        uniqueID: '',
        validcode: ''
      },
      rules: {
        loginName: [
          requiredRules('用户名')
        ],
        loginPwd: [
          requiredRules('登录密码')
        ],
        validcode: [
          requiredRules('验证码')
        ]
      },
      imgSrc: '',
      loading: false
    }
  },
  computed: {},
  created() {
    this.$bus.$on('login.show', () => {
      this.handleLogin()
    })
  },
  methods: {
    handleOpened() {
      this.handleRefreshCode()
    },
    handleLogin() {
      if (this.$refs.loginForm && this.$refs.loginForm.resetFields) {
        this.$refs.loginForm.resetFields()
      }
      this.dialogVisible = true
    },
    handleLoginSubmit() {
      if (this.loading) {
        return false
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          getApi('kingdom.kfts.set_aus_login_inv', 'V1.0', {
            ...this.form,
            password: kdes.getDes(this.form.loginPwd),
            loginPwd: kdes.getDes(this.form.loginPwd),
            markcode: '0',
            loginType: 'username',
            newVersion: '1'
          }).then((kdjson) => {
            this.loading = false
            const flag = kdjson.flag
            const items = kdjson.items
            if (flag === '1' && items) {
              loginUserRequest(() => {
                this.dialogVisible = false
                // 事件总线触发登录完成事件
                this.$bus.$emit('logined')
              }, () => {
                // 事件总线触发未登录事件
                this.$bus.$emit('no-login')
              })
            } else {
              this.$message({
                message: kdjson.msg || '登录失败！',
                type: 'error'
              })
              this.handleRefreshCode()
            }
          }).catch((kdjson) => {
            this.loading = false
            this.$message({
              message: kdjson.msg,
              type: 'error'
            })
            this.handleRefreshCode()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleRefreshCode() {
      getApi('kingdom.kfts.get_image_validecode', 'V1.0', {}).then((kdjson) => {
        const flag = kdjson.flag
        const items = kdjson.items
        if (flag === '1' && items) {
          this.form.uniqueID = items[0].uniqueID
          this.imgSrc = items[0].validcode
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.volidcode {
  img {
    width: 120px;
    height: 40px;
    border: 1px solid #dcdfe6;
    margin-left: 5px;
    border-radius: 4px;
    color: #9b9ea5;
  }
}
.login{
  // ::v-deep {
  //   .el-dialog__header {

  //   }
  // }
  .login-btn {
    height: 2.25rem;
    padding: 0 0.75rem;
    line-height: 2.25rem;
    border-radius: 0.375rem;
    background-color: #ffffff1e;
    cursor: pointer;
    display: none;
    i {
      margin-left: 0.3125rem;
    }
  }
}
.login-dialog {
  .login-alert{
    margin-bottom: 22px;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 0 20px 20px;
    }
  }
}
</style>
