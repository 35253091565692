<!--
 * @Author: 段海兵
 * @Date: 2022-10-24 13:21:06
 * @LastEditors: 段海兵
 * @Description: Hearder组件
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="header flex y-center">
    <div class="title flex-1 cl-white" @click="goHome">{{ title }}</div>
    <!-- <div class="routes flex-1 t-r">
      <router-link to="/">Go to Home</router-link>
      <router-link to="/result">Go to result</router-link>
      <router-link to="/screen">Go to screen</router-link>
    </div> -->
    <div class="user-info">
      <login v-if="!loginName">登录</login>
      <user-operator v-else />
    </div>
  </div>
</template>

<script>
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
import Login from './login'
import UserOperator from './userOperator'
import { mapState } from 'vuex'
export default {
  name: 'Header',
  components: {
    Login,
    UserOperator
  },
  data() {
    return {
      title: setting.title
    }
  },
  computed: mapState({
    loginName: state => state.account.loginName
  }),
  methods: {
    goHome() {
      this.$router.replace({
        name: 'Home'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 5rem;
  padding: 0 6.25rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  .title {
    padding-right: 1.25rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .user-info {
    padding-left: 1.25rem;
  }
}
</style>
