/*
 * @Author: 段海兵
 * @Date: 2022-10-24 10:09:58
 * @LastEditors: 段海兵
 * @Description: 路由配置
 * @email: duanhb@bayconnect.com.cn
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// import Home from '@/pages/home'
// import Result from '@/pages/result'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash', // require service support
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import('@/pages/home') // Home
        },
        {
          path: '/result',
          name: 'Result',
          component: () => import('@/pages/result') // Result
        }
      ]
    },
    {
      path: '/screen',
      name: 'Screen',
      component: () => import('@/pages/screen') // Result
    }
  ]
})

export default router
