/*
 * @Author: 段海兵
 * @Date: 2022-10-24 10:55:35
 * @LastEditors: 段海兵
 * @Description: 配置文件
 * @email: duanhb@bayconnect.com.cn
 */
const setting = {
  // 网站标题
  title: '北京市碳排放权电子交易平台有偿竞价系统',
  // 接口地址
  httpAddress: 'http://10.60.69.66:90',
  // 电子竞价信息刷新时间间隔  单位 S 秒
  bidRefreshTime: 10,

  // 可以进入竞价大厅的时间
  bidHomeSplitTime: '2023/07/05 15:00:00',

  // 可以做出金操作的时间
  withdrawSplitTime: '2023/07/05 15:10:00',

  // 竞价公开时间（用于标的状态判断）
  bidStatusSplitTime: '2023/07/05 15:00:00',

  // 缴纳保证金按钮是否可点击
  payMoneyStatus: false,

  // 申报竞买量提示
  buyVolTips: '<p style="white-space: normal;word-break: break-all;font-size: 0.94rem;line-height: 1rem;positon:relative;margin-top: -5px;text-align: justify;">单个重点碳排放单位申报的竞买量不得超过本次发放总量的15%;单个自愿参与交易的单位申报的竞买量不得超过本次发放总量的3%,所有参与竞买的自愿参与交易的单位竞买总量不得超过本次发放总量的20%。</p>',
  // 竞价成交凭证打印提示
  voucherPrintTips: '<p style="white-space: normal;word-break: break-all;">1. 参与人竞买所得配额（BEA-P2023）有效期到《北京市生态环境局关于做好2024年本市碳排放单位管理和碳排放权交易试点工作的通知》（京环发〔2024〕8号）明确的履约截止日期。</p><p style="white-space: normal;word-break: break-all;">2. 本凭证被伪造、变造、篡改的，不具有法律效力。</p><p style="white-space: normal;word-break: break-all;">3. 本交易凭证最终解释权归北京绿色交易所所有。</p>',

  // 接口公用入参
  stationtype: '4',
  // 请求入参是否加密
  encodeParam: false
}
module.exports = {
  renderSetting() {
    return {
      ...setting
    }
  },
  renderWebSetting() {
    return window.webConfig
  }
}
