const { renderWebSetting } = require('@/setting')
const setting = renderWebSetting()
// 日期对象格式化方法
// eslint-disable-next-line no-extend-native
Date.prototype.format = function(format) {
  const o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    'S': this.getMilliseconds()
    // millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

// 格式化数字 三位一逗 入参： 数字 保留小数位数 是否添加正负符号
export function fixNum(num, fix, addCart = false) {
  if (!num && num !== 0) {
    return ''
  }
  let cart = ''
  if (addCart && Number(num) > 0) {
    cart = '+'
  }
  const defaultFix = fix ? Number(fix) : 0
  let numbers = []
  if (typeof num === 'string' && !/^[\d.]+$/.test(num)) {
    return num
  } else if (typeof num === 'string') {
    num = Number(num).toFixed(defaultFix)
    numbers = num.split('.')
    return cart + numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '')
  } else if (typeof num === 'number') {
    num = num.toFixed(defaultFix)
    numbers = num.split('.')
    return cart + numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '')
  }
  return ''
}

// 格式化百分比 三位一逗并加上% num值 fix保留小数位数 isMult是否已经乘以100
export function formatPercent(num, fix = 2, isMult) {
  if (!num && num !== 0) {
    return ''
  }
  let numbers = []
  if (typeof num === 'string' && !/^[\d.]+$/.test(num)) {
    return num
  } else if (typeof num === 'string') {
    if (!isMult) {
      num = num * 100
    }
    num = num.toFixed(fix)
    numbers = num.split('.')
    return numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '') + '%'
  } else if (typeof num === 'number') {
    if (!isMult) {
      num = num * 100
    }
    num = num.toFixed(fix)
    numbers = num.split('.')
    return numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '') + '%'
  }
  return ''
}

// 格式化百分比 三位一逗并加上% num值 fix保留小数位数 isMult是否已经乘以1000
export function formatMicrometer(num, fix, isMult = false) {
  if (!num && num !== 0) {
    return ''
  }
  let numbers = []
  if (typeof num === 'string' && !/^[\d.]+$/.test(num)) {
    return num
  } else if (typeof num === 'string') {
    if (!isMult) {
      num = num * 1000
    }
    if (fix !== undefined) num = num.toFixed(fix)
    numbers = (`${num}`).split('.')
    return numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '') + '‰'
  } else if (typeof num === 'number') {
    if (!isMult) {
      num = num * 1000
    }
    if (fix !== undefined) num = num.toFixed(fix)
    numbers = (`${num}`).split('.')
    return numbers[0].replace(/\B(?=(?:\d{3})+\b)/g, ',') + (numbers[1] ? '.' + numbers[1] : '') + '‰'
  }
  return ''
}
// 格式化日期 yyyy-MM-dd 入参：date
export function formatDate(date, format = '-') {
  if (!date) {
    return ''
  }
  return date.replace(
    /^([\d]{4})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2}[^\d]?[\d]{2}[^\d]?[\d]{2})?$/,
    `$1${format}$3${format}$5`
  )
}
// 格式化时间 hh:mm:ss 入参：time
export function formatTime(time, format = ':') {
  if (!time) {
    return ''
  }
  if (time.length < 6) {
    time = '0' + time
  }
  return time.replace(
    /^([\d]{4}[^\d]?[\d]{2}[^\d]?[\d]{2})?([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})$/,
    `$3${format}$5${format}$7`
  )
}
// 格式化日期 yyyy-MM-dd hh:mm:ss 入参：time
export function formatDateTime(time) {
  if (!time) {
    return ''
  }
  return time.replace(
    /^([\d]{4})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})$/,
    '$1-$3-$5 $7:$9:$11'
  )
}

// 将字符串转化为日期对象
export function toDate(str) {
  if (str) {
    const dateStr = str.replace(/^([\d]{4})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})([^\d])?([\d]{2})$/, '$1/$3/$5 $7:$9:$11')
    return new Date(dateStr)
  }
  return new Date()
}

// 将字符串转化为日期对象
export function getUrlParams(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURI(r[2])
  }
  return null
}
// 设置附件资源链接
export function setSoruceUrl(url) {
  if (!/^(http:\/\/|https:\/\/)/.test(url)) {
    return setting.httpAddress ? `${setting.httpAddress.replace(/\/$/, '')}${url}` : url
  }
  return url
}
// 设置富文本图片资源链接
export function setRichTxtUrl(richTxt) {
  if (richTxt) {
    return setting.httpAddress ? richTxt.replace(/(src=[\"\'])(\/fileserver[^\"\']*)([\"\'])/g, `$1${setting.httpAddress.replace(/\/$/, '')}$2$3`) : richTxt
  }
  return richTxt
}
