<!--
 * @Author: 段海兵
 * @Date: 2022-10-24 09:49:23
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div id="app">
    <router-view />
    <!-- <layout>
    </layout> -->
  </div>
</template>

<script>
// import layout from './layout'
export default {
  name: 'App',
  components: {
    // layout
  },
  created() {
  },
  mounted() {
    // 请求服务器时间
    this.$store.dispatch('getSystemTimeInfo').then(() => {
      // 事件总线触发系统时间获取完成事件
      this.$bus.$emit('time-loaded')
    }).catch(() => {})
    document.addEventListener('visibilitychange', () => {
      if (document.hidden === true) {
        // 页面被挂起
      } else {
        // 页面由挂起被激活
        // 请求服务器时间
        this.$store.dispatch('getSystemTimeInfo').then(() => {
          // 事件总线触发系统时间获取完成事件
          this.$bus.$emit('time-loaded')
        }).catch(() => {})
      }
    })
  }
}
</script>

<style lang="scss">
  @import "./assets/css/commom.scss";
</style>
