/* eslint-disable */
import store from '@/store'

// 表单校验规则
// 最小长度
export const minRules = (num, title) => ({
  min: num,
  message: `${title || ''}至少${num}个字`,
  trigger: ['change']
})

// 最大长度
export const maxRules = (num, title) => ({
  max: num,
  message: `${title || ''}最多${num}个字`,
  trigger: ['change']
})

export const numSizeRules = (type, num, title) => ({
  validator: (rule, value, callback) => {
    // 返回true表示校验通过，返回false表示不通过
    var nm = Number(value)
    if (type === '<' && nm < Number(num)) {
      return callback()
    }
    if (type === '>' && nm > Number(num)) {
      return callback()
    }
    if (type === '<=' && nm <= Number(num)) {
      return callback()
    }
    if (type === '>=' && nm >= Number(num)) {
      return callback()
    }
    return callback(new Error(`${title}必须${type === '<' ? '小于' : type === '>' ? '大于' : type === '<=' ? '小于等于' : '大于等于'}${num}`))
  },
  trigger: ['change']
})

// 中文
export const zhRules = (title) => ({
  pattern: /^[\u4E00-\u9FA5]*$/,
  message: `${title || ''}只能输入中文`,
  trigger: ['change']
})

// 必填
export const requiredRules = (title) => ({
  required: true,
  message: `${title || ''}不能为空`,
  trigger: ['change', 'blur']
})

// 手机 | 固定电话
export const telRules = (title) => ({
  pattern: /(^1\d{10}$)|(^0\d{2,3}-\d{7,8}(-[\d]{1,4})?$)/,
  message: `${title || ''}只支持手机号格式或固定电话格式`,
  trigger: ['change']
})

// 手机
export const mobileRules = (title) => ({
  pattern: /^1\d{10}$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})
// 空格
export const spaceRules = (title) => ({
  pattern: /^[^\s]*$/,
  message: `${title || ''}不能输入空格`,
  trigger: ['change', 'blur']
})
// 固定电话
export const phoneRules = (title) => ({
  pattern: /^0\d{2,3}-\d{7,8}(-[\d]{1,4})?$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})
// 传真
export const faxnoRules = (title) => ({
  pattern: /^0\d{2,3}-\d{7,8}(-[\d]{1,4})?$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})

// 注册用户名
export const userNameRules = (title) => ({
  pattern: /^[a-zA-Z0-9]{3,20}$/,
  message: `请输入3-20位由数字字母组成的用户名！`,
  trigger: ['change']
})

// 邮编
export const postcodeRules = (title) => ({
  pattern: /^[0-9]{6}$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})
// 金额
export const moneyRules = (title) => {
  return ({
    pattern: /^([0-9]{1}[0-9]{0,11})(\.[0-9]{1,2})?$|^(0?\.(?:0[1-9]|[1-9][0-9]?))$/,
    message: `${title || ''}只能是数字，最多保留两位小数`,
    trigger: ['change']
  })
}
// 数字
export const numRules = (title) => ({
  pattern: /^([1-9]\d*)$/,
  message: `${title || ''}只能是正整数`,
  trigger: ['change']
})
// 邮箱
export const emailRules = (title) => ({
  pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})
// 网址
export const urlRules = (title) => ({
  pattern: /^(\w+:\/\/)?\w+(\.\w+)+.*$/,
  message: `${title || ''}格式不正确`,
  trigger: ['change']
})

// 身份证
export const idRules = (statusFunc, title) => {
  return ({
    validator: (rule, value, callback) => {
      if (typeof statusFunc === 'function' && statusFunc()) {
        var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1] // 加权因子;
        var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2] // 身份证验证位值，10代表X;

        if (value.length == 15) {
          return callback(new Error('请检查输入证件号码格式'))
        } else if (value.length == 18) {
          var a_idCard = value.split('') // 得到身份证数组
          var status1 = isValidityBrithBy18IdCard(value)
          var status2 = isTrueValidateCodeBy18IdCard(a_idCard)
          var statusBirth = verifyBirthday(value)
          if (status1 && status2 && statusBirth) {
            return callback()
          } else if (status1 && status2 && !statusBirth) {
            return callback(new Error('年龄必须大于等于18岁小于60岁'))
          }
          return callback(new Error('请检查输入证件号码格式'))
        }
        return callback(new Error('请检查输入证件号码格式'))

        function isTrueValidateCodeBy18IdCard(a_idCard) {
          var sum = 0 // 声明加权求和变量
          if (a_idCard[17].toLowerCase() == 'x') {
            a_idCard[17] = 10 // 将最后位为x的验证码替换为10方便后续操作
          }
          for (var i = 0; i < 17; i++) {
            sum += Wi[i] * a_idCard[i] // 加权求和
          }
          var valCodePosition = sum % 11 // 得到验证码所位置
          if (a_idCard[17] == ValideCode[valCodePosition]) {
            return true
          }
          return false
        }
        //判断是否大于等于18岁 小于60岁
        function verifyBirthday(idCard18) {
          var year = idCard18.substring(6, 10)
          var month = idCard18.substring(10, 12)
          var day = idCard18.substring(12, 14)

          var nowdate = store.state.system.time;
          var nowyear = nowdate.getFullYear() + '';
          var nowmonth = nowdate.getMonth() + 1 + '';
          var nowday = nowdate.getDate() + '';
          if (nowmonth.length == 1) nowmonth = '0' + nowmonth;
          if (nowday.length == 1) nowday = '0' + nowday;
          if (nowyear - year > 18) {
            if (nowyear - year < 60) {
              return true;
            } else if (nowyear - year == 60) {
              if (nowmonth < month) {
                return true;
              } else if (nowmonth == month) {
                if (nowday < day) {
                  return true;
                }
              }
            }
          } else if (nowyear - year == 18) {
            if (nowmonth > month) {
              return true;
            } else if (nowmonth == month) {
              if (nowday >= day) {
                return true;
              }
            }
          }
          return false;
        }
        function isValidityBrithBy18IdCard(idCard18) {
          var year = idCard18.substring(6, 10)
          var month = idCard18.substring(10, 12)
          var day = idCard18.substring(12, 14)
          var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day))
          // 这里用getFullYear()获取年份，避免千年虫问题
          if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
            return false
          }
          return true
        }

        function isValidityBrithBy15IdCard(idCard15) {
          var year = idCard15.substring(6, 8)
          var month = idCard15.substring(8, 10)
          var day = idCard15.substring(10, 12)
          var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day))
          // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
          if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
            return false
          }
          return true
        }
      }

      return true
    },
    // message: '请检查输入证件号码格式',// ，并且年龄不能小于18岁或大于60岁
    trigger: ['blur']
  })
}

// 统一社会信用代码
export const usccRules = (statusFunc, title) => {
  return ({
    validator: (rule, value, callback) => {
      if (typeof statusFunc === 'function' && statusFunc()) {
        const reg = /^[\dA-Z]{18}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只能由18位大写字母或数字组成'))
        }
      } else {
        callback()
      }
    },
    trigger: ['blur']
  })
}

// 登录密码校验规则
export const passwordRules = (title) => ({
  validator: (rule, value, callback) => {
    // 返回true表示校验通过，返回false表示不通过
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-zA-Z]|[0-9]){8,16}$/
    if (reg.test(value)) {
      callback()
    } else {
      callback(new Error(`${title || ''}只能8-16位字符，至少包含数字、字母和标点符号中的两种`))
    }
  },
  trigger: ['change']
})
// 交易密码校验规则
export const tradePwdRules = (title) => ({
  validator: (rule, value, callback) => {
    // 返回true表示校验通过，返回false表示不通过
    var reg = /^[\d]{6}$/
    if (reg.test(value)) {
      callback()
    } else {
      callback(new Error(`${title || ''}只能是6位数字`))
    }
  },
  trigger: ['change']
})
// 百分比校验规则
export const percentagePwdRules = (title) => ({
  validator: (rule, value, callback) => {
    // 返回true表示校验通过，返回false表示不通过
    var nm = Number(value)
    if (nm >= 0 && nm <= 100) {
      callback()
    } else {
      callback(new Error(`${title || ''}请输入正确的百分比`))
    }
  },
  trigger: ['change']
})
// 短信验证码校验规则
export const verificationRules = () => ({
  validator: (rule, value, callback) => {
    // 返回true表示校验通过，返回false表示不通过
    var nm = String(value)
    if (nm.length == 6) {
      return callback()
    }
    return callback(new Error('请输入6位短信验证码'))
  },
  trigger: ['change']
})
