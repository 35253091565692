<!--
 * @Author: 段海兵
 * @Date: 2022-11-14 17:33:27
 * @LastEditors: 段海兵
 * @Description: 页脚
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="footer">
    <div class="footer-content">
      <p class="cl-white"><span class="mr-100">电话：010-57382535 / 010-57382560</span><span class="mr-100">传真：010-57382500</span><span>邮编：101100</span></p>
      <p class="cl-white"><span class="mr-100">地址：北京市通州区观音庵街2号院保利大都汇T3六层</span><a href="https://beian.miit.gov.cn/" target="_blank"><font color="white">京ICP备09015338号-13</font></a><a href="http://www.beian.gov.cn/portal/index.do" target="_blank"><font color="white">京公网安备11010202007723号</font></a></p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {}
}
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 0 6.25rem;
  .footer-content{
    height: 100%;
    background-image: url("~@/assets/images/bg.png");
    background-repeat: no-repeat;
    // background-position: center center;
    background-size: cover;
    overflow: hidden;
    padding: 1rem 0;

    border-radius: .5rem .5rem 0 0;
    p {
      text-align: center;
      font-size: .875rem;
      line-height: 1.5rem;
      margin: 0;
    }
    a {
      margin-right: 1rem;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
</style>
