<!--
 * @Author: 段海兵
 * @Date: 2022-10-24 11:15:54
 * @LastEditors: 段海兵
 * @Description: 布局组件
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div
    :class="{
      'app-container': true,
      'bg-grey': true
    }"
  >
    <div class="container-bg" />
    <Header />
    <div class="page scrollbar">
      <!-- <slot /> -->
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from './header'
import Footer from './footer'
import { refreshUserRequest } from '@/utils/userInfo'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {}
  },
  computed: {},
  created() {
    // 刷新页面时刷新用户数据
    refreshUserRequest(() => {
      // 事件总线触发登录完成事件
      this.$bus.$emit('logined')
    }, () => {
      // 事件总线触发未登录事件
      this.$bus.$emit('no-login')
    })
  }
}
</script>

<style scoped lang="scss">
.app-container {
  width: 100%;
  height: 100vh;
  position: relative;
  color: #494f55;
  padding: 6rem 0;

  .container-bg {
    width: 100%;
    height: 24.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: url("~@/assets/images/bg.png");
    background-repeat: no-repeat;
    // background-position: center center;
    background-size: cover;
  }

  .page {
    width: 100%;
    height: 100%;
    padding: 0 6.25rem;
    position: relative;
    z-index: 2;
    overflow-y: auto;
  }
  ::v-deep {
    .el-empty {
      padding: 2.5rem 0;
      .el-empty__image {
        width: 10rem;
      }
      .el-empty__description{
        margin-top: 1.25rem;
        p {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
