<!--
 * @Author: 段海兵
 * @Date: 2022-10-25 16:27:06
 * @LastEditors: 段海兵
 * @Description: 账户登录后操作菜单
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div>
    <div class="login-in cl-white">
      <el-tooltip popper-class="tooltip-transparent" effect="dark" content="账户信息" placement="bottom">
        <i class="el-icon-user" @click="handleClick('account')" />
      </el-tooltip>
      <!-- <span @click="handleClick('account')"> {{ loginName }}</span> -->
      <!-- <el-tooltip popper-class="tooltip-transparent" effect="dark" content="修改密码" placement="bottom">
        <i class="el-icon-lock ml-100" @click="handleClick('password')" />
      </el-tooltip> -->
      <el-tooltip popper-class="tooltip-transparent" effect="dark" content="退出登录" placement="bottom">
        <i class="el-icon-switch-button ml-100" @click="handleLogout()" />
      </el-tooltip>
    </div>
    <password ref="password" />
    <user-info ref="account" />
  </div>
</template>

<script>
import Password from './password'
import UserInfo from './userInfo'
import { logout } from '@/utils/request'
import { mapState } from 'vuex'
export default {
  name: 'UserOperator',
  components: {
    Password,
    UserInfo
  },
  data() {
    return {}
  },
  computed: mapState({
    loginName: state => state.account.loginName
  }),
  methods: {
    handleClick(e) {
      this.$refs[e].handleShow()
    },
    handleLogout() {
      this.$confirm('确认退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        logout().then(kdjson => {
          loading.close()
          const flag = kdjson.flag
          if (flag === '1') {
            this.$store.dispatch('clearAccountInfo')
            this.$bus.$emit('logouted')
          }
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.login-in {
  height: 2.25rem;
  padding: 0 0.75rem;
  line-height: 2.25rem;
  border-radius: 0.375rem;
  background-color: #ffffff1e;
  i, span {
    // margin-left: 0.3125rem;
    cursor: pointer;
  }
}
</style>
