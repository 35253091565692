/*
 * @Author: 段海兵
 * @Date: 2022-10-25 10:47:19
 * @LastEditors: 段海兵
 * @Description: 请求接口公用文件
 * @email: duanhb@bayconnect.com.cn
 */
import axios from 'axios'
import { getAjaxParams } from './paramsEncode'
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
const service = axios.create({
  baseURL: setting.httpAddress, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 30000,
  method: 'post'
})

service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    return config
  },
  error => {
    console.log('err', error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status <= 504) {
      if (response.status === 201 || response.status === 204 || response.status === 202) {
        response.status = 200
      }
      return response.data.kdjson
    }
  },
  error => {
    let msg = ''
    if (error.response) {
      const { status, statusText } = error.response
      msg = statusText || `${status} error`
    }
    return Promise.reject({
      flag: '0',
      msg
    })
  }
)

export function getApi(soc, version, params) {
  const data = getAjaxParams(soc, version, params)
  return service({ url: `/api`, data })
}
export function loginName() {
  return service({ url: `/login_name` })
}
export function logout() {
  return service({ url: `/logout` })
}
