/*
 * @Author: 段海兵
 * @Date: 2022-10-24 09:49:23
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
 */
import Vue from 'vue'
import App from './App.vue'
import Print from 'vue-print-nb'
import './plugins/element'
import router from './router'
import store from './store'
import { fixNum } from './utils'
// 初始化过滤器
require('./filter/filter')
Vue.use(Print)
Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$fixNum = fixNum
new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  render: h => h(App),
  router,
  store
}).$mount('#app')
