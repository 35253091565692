/*
 * @Author: 段海兵
 * @Date: 2022-01-18 11:42:45
 * @LastEditors: 段海兵
 * @Description: 系统刷新 或者登录后需要请求的公用接口
 * @email: duanhb@bayconnect.com.cn
 */
import store from '@/store'
// import { MessageBox } from 'element-ui'

export const refreshUserRequest = (callBack, errCallback) => {
  // 查询用户登录信息
  store.dispatch('getLoginName').then(() => {
    // 如果已登录,获取用户信息
    store.dispatch('getAccountInfo').then((kdjson) => {
      const items = kdjson.items
      if (items) {
        callBack && callBack(items)
      } else {
        errCallback && errCallback()
      }
    }).catch(() => {
      errCallback && errCallback()
    })
  }).catch(() => {
    errCallback && errCallback()
  })
}

export const loginUserRequest = (callBack, errCallback) => {
  // 查询用户登录信息
  store.dispatch('getLoginName').then(() => {
    // 登录成功,获取用户信息
    store.dispatch('getAccountInfo').then((kdjson) => {
      const items = kdjson.items
      if (items) {
        callBack && callBack(items)
      } else {
        errCallback && errCallback()
      }
    }).catch(() => {
      errCallback && errCallback()
    })
  }).catch(() => {
    errCallback && errCallback()
  })
}
