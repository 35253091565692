/*
 * @Author: 段海兵
 * @Date: 2022-10-25 11:00:40
 * @LastEditors: 段海兵
 * @Description: 系统信息
 * @email: duanhb@bayconnect.com.cn
 */
import { getApi } from '@/utils/request'

// 计时器 用于实时更新时间
let timer = null
export default {
  state: {
    // 系统时间
    time: new Date(),
    // 按分钟更新的时间
    minuteTime: new Date()
  },
  mutations: {
    // 保存系统时间信息
    setSystemTime(state, time) {
      state.time = time
    },
    setMinuteTime(state, time) {
      state.minuteTime = time
    }
  },
  getters: {},
  actions: {
    /**
     * 获取系统时间信息
     * @param state
     * @param commit
     * @returns {Promise<unknown>}
     */
    getSystemTimeInfo({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        getApi('kingdom.kfts.get_system_oracle_time', 'V1.0', { ...params })
          .then(kdjson => {
            const flag = kdjson.flag
            const items = kdjson.items
            if (flag === '1' && items) {
              let nowDate = new Date(items[0].databasetime.replace(/([\d]{4})-?([\d]{2})-?([\d]{2})[\s]?([\d]{2}):?([\d]{2}):?([\d]{2})/, '$1/$2/$3 $4:$5:$6'))
              commit('setSystemTime', nowDate)
              commit('setMinuteTime', nowDate)
              if (timer) {
                clearInterval(timer)
                timer = null
              }
              timer = setInterval(() => {
                nowDate = new Date(nowDate.getTime() + 1000)
                commit('setSystemTime', nowDate)
                if (!nowDate.getSeconds()) {
                  commit('setMinuteTime', nowDate)
                }
              }, 1000)

              resolve(kdjson)
            } else {
              reject(kdjson)
            }
          }).catch(kdjson => {
            reject(kdjson)
          })
      })
    }
  }
}
